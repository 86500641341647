//! moment.js locale configuration
//! locale : japanese (ja)
//! author : LI Long : https://github.com/baryon

import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

moment.locale(window.locale, {
  months: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  monthsShort: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日',
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
  weekdaysMin: ['日', '月', '火', '水', '木', '金', '土'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'YYYY/MM/DD',
    LL: 'YYYY年M月D日',
    LLL: 'YYYY年M月D日Ah時m分',
    LLLL: 'M月D日',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: '年/月/日',
    LT: null, // '午:前', or 'H:mm'? TODO https://trello.atlassian.net/browse/PANO-2026
  },
  meridiemParse: /午前|午後/i,
  isPM(input) {
    return input === '午後';
  },
  meridiem(hour, minute, isLower) {
    if (hour < 12) {
      return '午前';
    } else {
      return '午後';
    }
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[今日] LT',
    nextDay: '[明日] LT',
    nextWeek: 'llll LT',
    lastDay: '[昨日] LT',
    lastWeek: 'llll LT',
    sameYear: 'llll LT',
    sameElse: 'll LT',
  }),
  relativeTime: {
    future: '%s後',
    past: '%s前',
    s: '数秒',
    m: '1分',
    mm: '%d分',
    h: '1時間',
    hh: '%d時間',
    d: '1日',
    dd: '%d日',
    M: '1ヶ月',
    MM: '%dヶ月',
    y: '1年',
    yy: '%d年',
  },
});
